<template>
  <el-select
    v-model="localValue"
    :placeholder="`${$t('actions.select')} ${$tc('models.course_type', 1)}`"
    :loading="loading"
    filterable
    value-key="id"
    @change="handleSelect"
  >
    <el-option
      v-for="courseType in courseTypes"
      :key="`${courseType.id}`"
      :label="$t(`types.course_types.${courseType.name}`)"
      :value="courseType"
    >
    </el-option>
  </el-select>
</template>

<script>
import { getCourseTypes } from '@/app/courses/api';

export default {
  props: {
    value: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      localValue: this.value,
      courseTypes: [],
      loading: false
    };
  },

  watch: {
    value(value) {
      this.localValue = value;
    }
  },

  methods: {
    handleSelect() {
      this.$emit('input', this.localValue);
    },

    async getCourseTypes() {
      this.loading = true;
      try {
        this.courseTypes = await getCourseTypes();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message({ message: this.$t('errors.general'), type: 'error' });
      }
    }
  },

  created() {
    this.getCourseTypes();
  }
};
</script>
