<template>
  <ui-form
    @submit="handleSubmit"
    :submitDisabled="invalid > 0 ? true : false"
    :submitText="$t('actions.save')"
    @cancel="handleCancel"
  >
    <section>
      <el-row>
        <el-badge class="item" :hidden="invalidNo === 0" :value="invalidNo">
          <ui-button
            type="grey"
            class=""
            :class="{ 'chosen-language-button': selectedLanguage === 'no' }"
            @click.prevent="toggleLanguage('no')"
            >no
          </ui-button>
        </el-badge>
        <el-badge
          class="item ml-2"
          :hidden="invalidEn === 0"
          :value="invalidEn"
        >
          <ui-button
            type="grey"
            class=""
            :class="{'chosen-language-button': selectedLanguage === 'en' }"
            @click.prevent="toggleLanguage('en')"
            >en
          </ui-button>
        </el-badge>
      </el-row>
    </section>

    <section v-if="selectedLanguage === 'no'">
      <ui-form-field :label="$t('fields.name')" rules="required">
        <el-input type="text" v-model="transTitle"></el-input>
      </ui-form-field>
      <ui-form-field :label="$t('fields.description')" rules="required">
        <rich-text-field v-model="transDescr"></rich-text-field>
      </ui-form-field>
    </section>

    <section v-if="selectedLanguage === 'en'">
      <ui-form-field :label="$t('fields.name')" rules="required">
        <el-input type="text" v-model="transTitle"></el-input>
      </ui-form-field>
      <ui-form-field :label="$t('fields.description')" rules="required">
        <rich-text-field v-model="transDescr"></rich-text-field>
      </ui-form-field>
    </section>

    <ui-form-field :label="$tc('models.course_type', 2)" rules="required">
      <el-badge class="item" :hidden="invalidType == 0" :value="1">
        <course-type-field v-model="course.courseType"></course-type-field>
      </el-badge>
    </ui-form-field>
    <!-- <section>
      <ui-form-field :label="$tc('modules.course_type', 2)">
        <el-select
          v-model="course.courseType"
          :placeholder="$tc('models.course_type', 1)"
          :value-key="course.courseType.id"
        >
          <el-option
            v-for="courseType in courseTypes"
            :key="`course_type_${courseType.id}`"
            :label="$t(`types.course_types.${courseType.name}`)"
            :value="courseType"
          />
        </el-select>
      </ui-form-field>
    </section> -->

    <section>
      <ui-form-field :label="$t('labels.valid_length.label')">
        <el-select
          v-model="course.validLength"
          :placeholder="$t('labels.valid_length.label')"
        >
          <el-option
            v-for="item in validLengths"
            :key="`valid_${item.id}`"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </ui-form-field>
    </section>

    <section>
      <ui-form-field :label="$t('models.exam')">
        <el-checkbox
          type="checkbox"
          v-model="course.hasExam"
          border
          :disabled="this.course.courseType.name === 'student'"
          >{{ $t('fields.has_exam') }}</el-checkbox
        >
      </ui-form-field>
    </section>

    <ui-form-field :label="$tc('models.building')" rules="required">
      <el-badge class="item" :hidden="invalidBuilding == 0" :value="1">
        <building-field v-model="course.building"></building-field>
      </el-badge>
    </ui-form-field>
  </ui-form>
</template>

<script>
import UiForm from '@/components/ui/UiForm';
import UiFormField from '@/components/ui/UiFormField';
import BuildingField from '@/components/form/BuildingField';
import CourseTypeField from '@/components/form/CourseTypeField';
import UiButton from '@/components/ui/UiButton';
import RichTextField from "@/components/form/RichTextField";

export default {
  props: {
    course: {
      type: Object,
      required: true
    },
    invalid: {
      type: Number,
      default: 0
    },
    invalidNo: {
      type: Number,
      default: 0
    },
    invalidEn: {
      type: Number,
      default: 0
    },
    invalidBuilding: {
      type: Number,
      default: 0
    },
    invalidType: {
      type: Number,
      default: 0
    }
  },

  components: {
    UiButton,
    UiForm,
    UiFormField,
    BuildingField,
    CourseTypeField,
    RichTextField
  },

  data: function() {
    return {
      selectedLanguage: 'no',
      validLengths: [
        { id: 0, label: this.$t('labels.valid_length.none'), value: null },
        {
          id: 1,
          label: this.$t('labels.valid_length.some', { amount: 6 }),
          value: 6 * 30
        },
        {
          id: 2,
          label: this.$t('labels.valid_length.some', { amount: 12 }),
          value: 12 * 30
        },
        {
          id: 3,
          label: this.$t('labels.valid_length.some', { amount: 24 }),
          value: 24 * 30
        }
      ]
    };
  },

  computed: {
    transTitle: {
      get() {
        return this.course.title.filter(
          t => t.lang === this.selectedLanguage
        )[0].value;
      },
      set(value) {
        this.course.title.filter(
          t => t.lang === this.selectedLanguage
        )[0].value = value;
      }
    },
    transDescr: {
      get() {
        const trans = this.course.description.filter(
          t => t.lang === this.selectedLanguage
        )[0].value;
        return trans === '<p></p>' ? '' : trans;
      },
      set(value) {
        this.course.description.filter(
          t => t.lang === this.selectedLanguage
        )[0].value = value;
      }
    }
  },

  methods: {
    handleSubmit() {
      this.$emit('submit');
    },
    toggleLanguage(value) {
      this.selectedLanguage = value;
    },

    handleCancel() {
      this.$emit('cancel');
    }
  },

  created() {}
};
</script>

<style>
.chosen-language-button {
  background-color: #c4c4c4 !important;
}
</style>
