<template>
  <transition name="fade">
    <div v-if="show" class="modal-container" v-loading="loading">
      <div class="w-full max-w-2xl">
        <ui-card>
          <p class="py-4">
            {{ $t('actions.copy') }}
            {{ course.title.filter(t => t.lang === $i18n.locale)[0].value }}
          </p>
          <!-- <em v-if="info" class="py-4">{{ info }}asdf</em> -->

          <ui-form-field :label="$tc('models.company', 1)" rules="required">
            <el-select
              v-if="companies"
              v-model="selectedCompany"
              :placeholder="$tc('models.company', 1)"
              :loading="loading"
              @change="handleCompanyChange"
              :disabled="loading"
            >
              <el-option
                v-for="company in companies"
                :key="company.id"
                :label="company.name"
                :value="company.id"
              />
            </el-select>
          </ui-form-field>

          <ui-form-field :label="$tc('models.building', 1)" rules="required">
            <el-select
              v-if="companies"
              v-model="selectedBuilding"
              :placeholder="$tc('models.building', 1)"
              :disabled="!buildings || buildings.length === 0 || loading"
              :loading="loading"
            >
              <el-option
                v-for="building in buildings"
                :key="building.id"
                :label="building.name"
                :value="building.id"
              />
            </el-select>
          </ui-form-field>

          <template v-slot:footer>
            <div class="flex">
              <ui-link
                type="primary"
                :disabled="!selectedBuilding"
                @click="handleConfirm"
                >{{ $t('actions.copy') }}</ui-link
              >
              <ui-link type="text" class="ml-4" @click="handleCancel">{{
                cancelText
              }}</ui-link>
            </div>
          </template>
        </ui-card>
      </div>
    </div>
  </transition>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import UiFormField from '@/components/ui/UiFormField';
import UiLink from '@/components/ui/UiLink';
import { getCompanies, getCompanyBuildings } from '../../companies/api';
import { copyCourse } from '../api';

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    course: {
      type: Object,
      required: true
    },
    confirmText: {
      type: String,
      default: function() {
        return this.$t('actions.confirm');
      }
    },
    cancelText: {
      type: String,
      default: function() {
        return this.$t('actions.cancel');
      }
    }
  },

  components: {
    UiCard,
    UiFormField,
    UiLink
  },

  data: function() {
    return {
      loading: false,
      companies: [],
      buildings: [],
      selectedCompany: null,
      selectedBuilding: null
    };
  },

  methods: {
    async init() {
      this.loading = true;
      try {
        this.companies = await getCompanies();
        this.loading = false;
      } catch (err) {
        this.feedbackGetCompaniesFailed(err);
        this.loading = false;
      }
    },

    handleCompanyChange(selected) {
      this.selectedBuilding = null;
      this.loadBuilding(selected);
    },

    async loadBuilding(companyId) {
      this.loading = true;
      try {
        this.loading = false;
        this.buildings = await getCompanyBuildings(companyId);
      } catch (err) {
        this.feedbackGetBuildingsFailed(companyId, err);
        this.loading = false;
      }
    },

    feedbackGetCompaniesFailed(err) {
      this.$message({
        message: this.$t('errors.load', {
          msg: this.$tc('models.company', 2)
        }),
        type: 'error'
      });
    },

    feedbackGetBuildingsFailed(companyId, err) {
      this.$message({
        message: this.$t('errors.load', {
          msg: this.$tc('models.building', 2)
        }),
        type: 'error'
      });
    },

    feedbackCopySuccess() {
      const msg = this.$t('feedback.task_success', {
        action: this.$t('actions.copy')
      });
      this.$message({ message: msg, type: 'success', duration: 2000 });
    },

    feedbackCopyFailed(err) {
      const msg = this.$t('errors.failed', {
        action: this.$t('actions.copy')
      });
      this.$message({ message: msg, type: 'error' });
    },

    async handleConfirm() {
      const payload = {
        courseId: this.course.id,
        buildingId: this.selectedBuilding
      };

      if (!payload.courseId || !payload.buildingId) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
      }

      this.loading = true;
      try {
        const newId = await copyCourse(this.course.id, payload);
        this.feedbackCopySuccess();
        this.loading = false;
        const route = { name: 'courses-show', params: { id: newId } };
        this.$router.push(route);
        this.show = false;
      } catch (err) {
        this.feedbackCopyFailed(err);
        this.loading = false;
      }
    },

    handleCancel() {
      this.selectedCompany = null;
      this.selectedBuilding = null;
      this.$emit('cancel');
    }
  },

  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.modal-container {
  @apply fixed inset-0 flex items-center justify-center px-3 py-6;
  background-color: rgba(0, 0, 0, 0.5);

  @screen sm {
    @apply px-4 py-8;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
